import { Typography as AlliumTypography, type TypographyProps } from '@telus-uds/components-web'
import { TextRenderer } from '../../../utils/text/TextRenderer'
import { renderSpacer } from 'src/siteBuilder/utils/renderSpacer'

import type { VariantColor, VariantSize } from '../RichText/TypographyVariantTypes'
import type { SpacerProps } from '../Spacer/Spacer'

export interface HeadingProps extends Omit<TypographyProps, 'children'> {
  text: string
  size?: Exclude<VariantSize, 'micro' | 'small' | 'large'>
  colour?: VariantColor
  inverse?: boolean
  bold?: boolean
  spacer?: SpacerProps
  heading?: Exclude<TypographyProps['heading'], true>
}

const Heading = ({
  text,
  size = 'h1',
  colour,
  inverse = false,
  bold = false,
  heading,
  align,
  spacer,
  ...rest
}: HeadingProps) => {
  const fieldPath = rest['data-sb-field-path'] as string
  const dataSet = {}
  /* istanbul ignore if: No need to test path */
  if (fieldPath) dataSet['sb-field-path'] = `${fieldPath} ${fieldPath}.text`

  let semanticTag: TypographyProps['tag']
  if (heading) {
    semanticTag = heading
  } else {
    if (size === 'display1' || size === 'display2') {
      semanticTag = 'h1'
    } else if (size === 'eyebrow') {
      semanticTag = null // TODO: assuming null for now
    } else {
      semanticTag = size
    }
  }

  return (
    <div data-testid="heading-testid">
      {renderSpacer(spacer)}
      <AlliumTypography
        variant={{
          size,
          colour,
          inverse,
          bold,
        }}
        tag={semanticTag}
        block={true}
        align={align}
        dataSet={dataSet}
      >
        <TextRenderer>{text}</TextRenderer>
      </AlliumTypography>
    </div>
  )
}

export default Heading
